import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ActivityCard, BlockQuote, DevelopedAtThinkZambia } from '@think-zambia-foundation/core-lib/components';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import {
  Toolbar,
} from '@material-ui/core';
import {
  chastenBanner,
  chastenIndependenceDay as eventsAndCalendarActivityImage,
  mathOnAWhiteBoard as educationalToursActivityImage,
  twoBoysHugging as communityActivityImage,
} from '../../assets/images';
import { ChastenFooterContent } from '../../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
  hero: {
    background: theme.palette.background.footer,
  },
  heroContainer: {
    backgroundImage: `url(${chastenBanner})`,
    backgroundSize: '50em',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
  },
  heroButton: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.5em',
    color: theme.palette.charcoal.main,
  },
  backToSchoolBlock: {
    fontStyle: 'italic',

  },
}));

function Home() {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chasten Education Centre | Home</title>
        <meta name="description" content="Chasten Education Centre is a modern school, offering pre-school, primary school, and secondary school. Children from the age of 2 years are welcome and are in our trained and experienced teaching staff's competent hands" />
        <link rel="Home" href="https://chastenzm.com/" />
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          className={classes.hero}
          xs={12}
        >
          <Container
            maxWidth="lg"
            className={classes.heroContainer}
          >
            <Toolbar />
            <ChastenFooterContent />
            <Toolbar />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Toolbar />
            <BlockQuote
              title="Our Philosophy"
              text="An athlete spends countless hours of training, eats healthy food,
           and sleeps for a good number of hours to be ready for competition.
           This discipline starts much before he has to compete; in fact, this
           makes him able to compete. Our system of education is much like this.
           We teach our children healthy habits that will prepare them for whatever
           career path they take on. Our discipline areas include, but are not
           limited to, timekeeping, respect for one another, submission to authority,
           good study habits, honesty, and truthfulness. These allow our students
           to retain the knowledge they receive and use it in an exam or later on in life."
            />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container
            maxWidth="lg"
            className={classes.heroContainer}
          >
            <Toolbar />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item md={4}>
                <ActivityCard
                  title="Events and Calendar"
                  text="Our school year comprises three terms; each of 13 teaching weeks, with one week break between each term. In October every year the school takes a..."
                  image={eventsAndCalendarActivityImage}
                  action={() => {}}
                  buttonText=""
                />
              </Grid>
              <Grid item md={4}>
                <ActivityCard
                  title="Educational Tours"
                  text="Chasten school arranges educational tours of certain institutions and places to help the understanding of things that are taught to pupils in class..."
                  image={educationalToursActivityImage}
                  action={() => {}}
                  buttonText=""
                />
              </Grid>
              <Grid item md={4}>
                <ActivityCard
                  title="Community"
                  text="Once in a year, the school requests parents of the pupils to donate to the school used clothing and accessories which are later distributed to needy..."
                  image={communityActivityImage}
                  action={() => {}}
                  buttonText=""
                />
              </Grid>
            </Grid>
            <Toolbar />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <DevelopedAtThinkZambia />
          <Toolbar />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
