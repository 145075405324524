import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MailSocialMediaIconLink,
  InstagramSocialMediaIconLink,
  FacebookSocialMediaIconLink,
} from '../Link';

function SocialMediaMenu() {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <MailSocialMediaIconLink />
      </Grid>
      <Grid item>
        <InstagramSocialMediaIconLink />
      </Grid>
      <Grid item>
        <FacebookSocialMediaIconLink />
      </Grid>
    </Grid>
  );
}

export default SocialMediaMenu;
