import React from 'react';
import Link from '@material-ui/core/Link';
import { Facebook, Instagram, MailOutline } from '@material-ui/icons';

const MailSocialMediaIconLink = () => (
  <Link color="inherit" href="mailto:admin@chastenzm.com" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
    <MailOutline />
  </Link>
);

const InstagramSocialMediaIconLink = () => (
  <Link color="inherit" href="https://instagram.com/chastenzm" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Instagram />
  </Link>
);

const FacebookSocialMediaIconLink = () => (
  <Link color="inherit" href="https://www.facebook.com/chastenzm" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
    <Facebook />
  </Link>
);

export {
  MailSocialMediaIconLink,
  InstagramSocialMediaIconLink,
  FacebookSocialMediaIconLink,
};
