import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from '../../styles';
import contactPageSchoolImage from '../../assets/images/brainstorming.jpg';
import { Footer } from '../../components';

const useLocalStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  contactCard: {
    padding: 21,
    height: '100%',
  },
}));

function Contact() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const googleFormId = '1FAIpQLScRZieKJJVCI0GTFuUUPJBZlQd3n7n3z45UWI1ZSS-TP_N3GQ';
    fetch(`https://docs.google.com/forms/d/e/${googleFormId}/formResponse?entry.2005620554=${firstName}&entry.53986450=${lastName}&entry.1045781291=${email}&entry.1439145190=${comment}`, {
      method: 'POST',
    }).catch(() => {
    }).finally(() => {
      setSubmitted(true);
    });
  };

  const [randomNumber1, setRandomNumber1] = useState(0);
  const [randomNumber2, setRandomNumber2] = useState(0);
  useEffect(() => {
    setRandomNumber1(Math.floor(Math.random() * 10));
    setRandomNumber2(Math.floor(Math.random() * 10));
  }, [firstName, lastName, email, comment]);

  const onMathCaptcha = (event) => {
    if ((randomNumber1 + randomNumber2) === parseInt(event.target.value, 10)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const classes = useStyles();
  const localStyles = useLocalStyles();
  return (
    <>
      <Hidden smDown>
        <Grid item sm={12}>
          <img src={contactPageSchoolImage} alt="chasten school" className={classes.contactPageHeroImage} />
        </Grid>
      </Hidden>
      <Container className={localStyles.container}>
        <Grid container spacing={6} align="left">
          { submitted === false
            ? (
              <Grid item sm={6}>
                <Card elevation={9} className={localStyles.contactCard}>
                  <CardContent>
                    <form onSubmit={handleSubmit}>
                      <FormControl>
                        <Grid container spacing={3}>
                          <Grid item sm={12}>
                            <Typography variant="h3">
                              For more information
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Please include your First name, Last name, and a reliable Email
                              by which we can reach you.
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <TextField
                              fullWidth
                              id="firstName"
                              onChange={onFirstNameChange}
                              placeholder="first name"
                              required
                              label="First name"
                              size="small"
                              variant="outlined"
                              InputProps={{ classes: { input: classes.textField } }}
                              InputLabelProps={{ className: classes.textField }}
                            />
                          </Grid>
                          <Grid item sm={6}>
                            <TextField
                              fullWidth
                              id="lastName"
                              onChange={onLastNameChange}
                              placeholder="last name"
                              required
                              label="Last name"
                              size="small"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <TextField
                              fullWidth
                              id="email"
                              type="email"
                              onChange={onEmailChange}
                              placeholder="email"
                              required
                              label="Email"
                              size="small"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              onChange={onCommentChange}
                              placeholder="Comment"
                              multiline
                              rows={8}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <Typography variant="body2">
                              what is
                              {' '}
                              {randomNumber1}
                              {' '}
                              plus
                              {' '}
                              {randomNumber2}
                              {' '}
                            </Typography>
                            <TextField onChange={onMathCaptcha} type="number" size="small" />
                          </Grid>
                          <Grid item sm={12}>
                            <Button disabled={disabled} type="submit" variant="contained" color="secondary" size="large">submit</Button>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )
            : (
              <Grid item sm={6} align="center">
                <Card elevation={6} className={localStyles.contactCard}>
                  <CardContent>
                    <br />
                    <br />
                    <br />
                    <Typography variant="h3">
                      Submitted!
                    </Typography>
                    <Typography variant="body2">
                      For more info, visit our about page
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          <Grid item sm={6} align="center">
            <Card elevation={6} className={localStyles.contactCard}>
              <CardContent>
                <Typography variant="h3">
                  Administration
                </Typography>
                <Typography variant="body2">
                  Chasten Education Centre
                  <br />
                  5073 Zebra St. Nkana East, Kitwe, Zambia
                  <br />
                  admin@chastenzm.com
                </Typography>
                <Typography variant="h3">
                  Online Support
                </Typography>
                <Typography variant="body2">
                  support@chastenzm.com
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
