import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '40ch',
    },
    width: '100%',
    '& .MuiFormLabel-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
    },
  },
  textField: {
    fontFamily: theme.typography.fontFamily,
  },
  contactPageHeroImage: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.background.footer,
    marginTop: 100,
    paddingTop: 100,
    paddingBottom: 100,
    paddingLeft: '25px',
    paddingRight: '25px',
  },
}));

export default useStyles;
