import React from 'react';
import {
  Container,
  Grow,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { DevelopedAtThinkZambia } from '@think-zambia-foundation/core-lib/components';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import SocialMediaMenu from '../Menu/SocialMediaMenu';

const useStyles = makeStyles(() => ({
}));

const information = [
  {
    text: 'Nkana East',
    description: 'Reception - Grade 7',
    href: '/admission',
  },
  {
    text: 'Ndeke',
    description: 'Reception - Grade 7',
    href: '/admission',
  },
  {
    text: 'Kalulushi Boys',
    description: 'Grade 8 - Grade 12',
    href: '/admission',
  },
  {
    text: 'Calendar',
    href: '/calendar',
  },
];

export function ChastenFooterContent() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grow
      in
      timeout={1000}
    >
      <div className={classes.footer}>
        <Grid container>
          <Grid item sm={6}>
            <List>
              <ListItem
                button
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/');
                }}
              >
                <ListItemText>
                  <Typography variant="h6">
                    Chasten Education Centre
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Pre-School, Primary School & Secondary School
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/');
                }}
              >
                <ListItemText>
                  <Typography variant="h6">
                    About Us
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    At Chasten, we care about the foundation of a child&apos;s education,
                    especially their early learning. For this reason, classes are
                    purposely arranged in small groups to offer individualized teaching
                    to the children. Children from the age of 2 years are welcome
                    and are in our trained and experienced teaching staff&apos;s competent hands.
                    The school offers pre-school, full primary education as well as secondary
                    education.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button>
                <ListItemText>
                  <Typography variant="body2">
                    ©
                    {' '}
                    { new Date().getFullYear() }
                    {' '}
                    Chasten Education Centre
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={3}>
            <List>
              {information.map((menuItem) => (
                <ListItem
                  button
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(menuItem.href);
                  }}
                  key={menuItem.text}
                >
                  <ListItemText>
                    <Typography variant="h6">
                      {menuItem.text}
                    </Typography>
                    <Typography variant="body2">
                      {menuItem.description}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
              <ListItem>
                <SocialMediaMenu />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={3}>
            <List>
              <ListItem button>
                <ListItemText>
                  <Typography variant="h6">
                    Administration
                  </Typography>
                  <Typography variant="body2">
                    Chasten Education Centre
                  </Typography>
                  <Typography variant="body2">
                    5073 Zebra St. Nkana East, Kitwe, Zambia
                  </Typography>
                  <Typography variant="body2">
                    admin@chastenzm.com
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button>
                <ListItemText>
                  <Typography variant="h6">
                    Online Support
                  </Typography>
                  <Typography variant="body2">
                    support@chastenzm.com
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button>
                <ListItemText>
                  <Typography variant="body2" gutterBottom>
                    By love through chastening we learn Heb 12:6
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    </Grow>
  );
}

export default function Footer() {
  return (
    <Container>
      <Toolbar />
      <ChastenFooterContent />
      <DevelopedAtThinkZambia />
      <Toolbar />
    </Container>
  );
}
