import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { BlockQuote } from '@think-zambia-foundation/core-lib/components';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  aboutContainer: {
    padding: '7em 0',
  },
}));

function About() {
  const classes = useStyles();
  return (
    <Container className={classes.aboutContainer}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BlockQuote
            title="Our Philosophy"
            text="An athlete spends countless hours of training, eats healthy food,
           and sleeps for a good number of hours to be ready for competition.
           This discipline starts much before he has to compete; in fact, this
           makes him able to compete. Our system of education is much like this.
           We teach our children healthy habits that will prepare them for whatever
           career path they take on. Our discipline areas include, but are not
           limited to, timekeeping, respect for one another, submission to authority,
           good study habits, honesty, and truthfulness. These allow our students
           to retain the knowledge they receive and use it in an exam or later on in life."
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
