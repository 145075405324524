import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  AppBar, Container, List, ListItem, ListItemText,
  Toolbar, IconButton, Drawer, Divider,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { chastenLogo } from '../../assets/images';

const drawerWidth = 375;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'black',
  },
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    color: 'black',
  },
  appBarPaddingDesktop: {
    marginTop: '9em',
  },
  appBarPaddingMobile: {
    marginTop: '2em',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  desktopMenuItem: {
    transition: 'transform 0.15s, color 0.25s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
      backgroundColor: 'inherit',
      color: theme.palette.background.footer,
    },
  },
}));

const mainMenu = [
  {
    text: 'about',
    href: '/',
    description: 'Chasten Education Centre',
  },
  {
    text: 'admission',
    href: '/admission',
    description: 'Requirements & Online Application',
  },
  {
    text: 'Calendar',
    href: '/calendar',
    description: 'Events & Important Dates',
  },
  {
    text: 'Contact',
    href: '/contact',
    description: 'Contact Information & Forms',
  },
];

function Header() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Hidden xsDown>
        <AppBar
          position="fixed"
          className={classes.appBar}
          elevation={1}
        >
          <Container maxWidth="lg">
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={6}
              >
                <Grid item>
                  <Link color="inherit" to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={chastenLogo} alt="Chasten Education Centre - Logo (png)" width="125px" />
                  </Link>
                </Grid>
                <Grid item>
                  <Grid container spacing={0}>
                    <List style={{ display: 'flex', flexDirection: 'row' }}>
                      {mainMenu.map((menuItem) => (
                        <ListItem
                          button
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(menuItem.href);
                          }}
                          key={menuItem.text}
                          className={classes.desktopMenuItem}
                        >
                          <ListItemText>
                            <Typography variant="h4">
                              {menuItem.text}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Hidden>
      <Hidden smUp>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          elevation={1}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Link color="inherit" to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={chastenLogo} alt="Chasten Education Centre - Logo (png)" width="176px" />
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {mainMenu.map((menuItem) => (
              <ListItem
                button
                component="a"
                href={menuItem.href}
                display="inline-block"
                key={menuItem.text}
              >
                <ListItemText>
                  <Typography variant="h4">
                    {menuItem.text}
                  </Typography>
                  <Typography variant="caption">
                    {menuItem.description}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <div className={classes.appBarPaddingMobile}>
          {' '}
        </div>
      </Hidden>
    </>
  );
}

export default Header;
