import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: [
        'Chasten-Medium',
        'Open Sans',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: 55,
        color: '#121213',
      },
      h2: {
        fontSize: 34,
        fontWeight: 300,
        lineHeight: 2,
        color: '#121213',
      },
      h3: {
        fontSize: 21,
        fontWeight: 300,
        lineHeight: 2,
      },
      h4: {
        fontSize: 16,
        textTransform: 'uppercase',
        letterSpacing: 0,
        color: '#121213',
      },
      body2: {
        fontFamily: 'Chasten-Book',
        fontSize: 13,
        fontWeight: 300,
        color: '#383839',
        lineHeight: 1.5,
      },
      body: {
        fontFamily: 'Chasten-Book',
      },
    },
    palette: {
      primary: {
        main: '#d3c5b7',
        dark: '#a29587',
        light: '#fff8e9',
      },
      secondary: {
        main: '#f48fb1',
        dark: '#bf5f82',
        light: '#ffc1e3',
      },
      charcoal: {
        main: '#383839',
        dark: '#121213',
        light: '#626263',
      },
      linen: {
        main: '#f5f3ee',
        dark: '#c2c0bc',
        light: '#ffffff',
      },
      background: {
        footer: '#f48fb1',
        default: '#f5f3ee',
      },
    },
  }),
);

export default theme;
