import React from 'react';
import {
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { KatangaStudentApplicationProvider } from '@think-zambia-foundation/katanga-lib/context';
import { ApplicationProvider } from '@think-zambia-foundation/core-lib/context';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Footer } from '../../components';
import ApplicationForm from './ApplicationForm';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: theme.palette.background.footer,
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Admission() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Toolbar />
      <Container className={classes.admissionContainer}>
        <Grid
          container
          spacing={6}
        >
          <Grid item xs={12}>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="pane1d-content" id="panel1d-header">
                <Typography variant="h4">
                  Enrollment & Fees
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom>
                  Chasten Education Centre cares about the foundation of a child&apos;s education,
                  especially their early learning. For this reason, classes are purposely
                  arranged in small groups to offer individualized teaching to the children.
                  Children from the age of 2 years are welcome and are in our trained and
                  experienced teaching staff&apos;s competent hands.
                  <br />
                  <br />
                  The school offers Pre-school, full primary education as well as secondary
                  education. In pre-school, there are three levels, namely:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Baby School: 2 - 3-year-olds
                    </li>
                    <li>Middle School: 3 - 4-year-olds</li>
                    <li>Reception School: 4 - 5-year-olds</li>
                    <li>Grade 1: 5 - 6-year-olds</li>
                  </ul>
                  <br />
                  Our curriculum is modern and tailored to fit both the Zambian and international
                  standards.
                  <br />
                  <br />
                  Enrollment Application forms are available for purchase from the Administration
                  Office at a minimal fee of K30. Any child above two years is eligible for
                  enrolment at Chasten Education Centre. For more information about our
                  competitive School Fees, please contact our Administration Office.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <Typography variant="h4" className={classes.heading}>
                  Nkana East
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom>
                  Offering Reception - Grade 7
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography variant="h4" className={classes.heading}>
                  Ndeke
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom>
                  Offering Reception - Grade 7
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Typography variant="h4" className={classes.heading}>
                  Kalulushi Boys
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" gutterBottom>
                  Now accepting applications for Grade 8 & 10
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <ApplicationProvider>
              <Paper square elevation={1} variant="outlined">
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography variant="h4" className={classes.heading}>
                    Apply Online
                  </Typography>
                </AccordionSummary>
                <Toolbar />
                {!submitted && (
                <ApplicationProvider>
                  <KatangaStudentApplicationProvider>
                    <ApplicationForm
                      createApplicationSuccessCallback={() => { setSubmitted(true); }}
                    />
                  </KatangaStudentApplicationProvider>
                </ApplicationProvider>
                )}
                {submitted && (
                  <Container>
                    <Typography align="center">Application submitted successfully!</Typography>
                  </Container>
                )}
                <Toolbar />
              </Paper>
            </ApplicationProvider>
          </Grid>
        </Grid>
      </Container>
      <Toolbar />
      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  );
}
