import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import {
  Header,
} from './components';

import {
  About,
  Admission,
  Calendar,
  Contact,
  Home,
} from './pages';

const useStyles = makeStyles({
  app: {
    padding: 0,
    margin: 0,
  },
});

function App() {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <Router>
        <Header />
        <Toolbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/admission" component={Admission} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
