import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleCalendarEvents } from '@think-zambia-foundation/core-lib/components';
import { Footer } from '../../components';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    padding: '7em 2em',
  },
  footer: {
    background: theme.palette.background.footer,
  },
}));

export default function Calendar() {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.calendarContainer}>
        <Grid container spacing={6}>
          <Grid item xs={12} align="left">
            <Typography variant="h2">
              Calendar & Events
            </Typography>
            <Typography variant="body2" gutterBottom>
              Our school year comprises three terms, each of 13 teaching weeks
              with a week break in between each.
            </Typography>
            <Typography variant="h3">
              Independence Day
            </Typography>
            <Typography variant="body2" gutterBottom>
              Every year, the school takes a day before the 24th of October to
              celebrate Zambia&apos;s Independence Day. On this day, pupils reflect
              on this nation&apos;s founding fathers and the fallen heroes who helped
              fight for the country&apos;s independence. Pupils perform traditional
              dances and plays depicting events that happened around Zambia&apos;s
              Independence.
            </Typography>
            <Typography variant="h3">
              Graduation
            </Typography>
            <Typography variant="body2" gutterBottom>
              During the Independence celebrations, the school conducts a graduation
              ceremony for pupils in Grade 7, the last Grade in primary school,
              and Reception Grade who graduate from pre-school to primary school.
              In December every year, the school organizes an end-of-year party for the children.
            </Typography>
            <Typography variant="h3">
              Educational Tours
            </Typography>
            <Typography variant="body2" gutterBottom>
              Chasten school arranges educational tours of established institutions
              and places to help the pupils understand what they learn in class.
            </Typography>
            <Typography variant="h3">
              Community Service
            </Typography>
            <Typography variant="body2" gutterBottom>
              Once a year, the school requests the pupils to donate to the school used clothing
              and accessories to distribute to needy children within the community and orphanages.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid className={classes.events}>
        <Grid item xs={12}>
          <GoogleCalendarEvents
            numResults={3}
            calendarId={process.env.REACT_APP_GOOGLE_CALENDAR_ID}
            API_KEY={process.env.REACT_APP_GOOGLE_API_KEY}
            CLIENT_ID={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          />
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  );
}
